<template lang="pug">
  v-container
    v-row
      v-col
        .details.mb-10
          v-layout(
            align-center
          )
            h1.text-h4 {{ tech.name }}
            v-col.d-flex
              .actions.ml-auto
                v-chip.mr-2(
                  :color="tech.status === 'ACTIVE' ? 'primary' : 'grey lighten-1'"
                  small
                  v-if="user.isAdmin"
                ) {{ tech.status }}
                v-tooltip(
                  top
                  v-if="user.isAdmin || user.isRegionAdmin || user.isShopAdmin"
                )
                  template(#activator="{ on, attrs}")
                    v-btn.px-0.mr-2.my-1(
                      v-on="on"
                      min-width="40px"
                      @click="deleteTech"
                      color="error"
                    )
                      v-icon mdi-delete
                  span Delete Tech
                v-btn(
                  v-if="user.isAdmin || user.isRegionAdmin || user.isShopAdmin"
                  :to="{ name: 'edit-tech', params: { techId: tech.id }}"
                  color="primary"
                )
                  v-icon(
                    left
                  ) mdi-pencil
                  | Edit Tech
</template>

<script>
import { useGet } from 'feathers-vuex'
import { computed } from '@vue/composition-api'

export default {
  name: 'Tech',
  props: {
    techId: {
      type: String,
      required: true
    }
  },
  setup (props, context) {
    const { Tech } = context.root.$FeathersVuex.api

    // Include this so we can join the region
    const params = computed(() => {
      return { query: { $eager: 'shop' } }
    })

    const { item: tech, hasLoaded } = useGet({
      model: Tech,
      params,
      id: props.techId
    })

    return {
      tech,
      hasLoaded
    }
  },
  computed: {
    user () {
      return this.$store.getters['auth/user']
    }
  },
  methods: {
    async deleteTech () {
      try {
        const message = 'Are you sure you want to delete this tech?'
        const isYes = await this.$confirm(message, { title: 'Warning' })

        const tech = this.tech

        if (isYes) {
          await this.tech.remove()
          this.$snackSuccess('Tech Deleted')
          this.$router.push({ name: 'shop-techs', params: { shopId: tech.shop_id } })
        }
      } catch (error) {
        this.$logError(error)
      }
    }
  },
  data: () => ({
    shops: [
      {
        name: 'Longhorn Collision',
        shop: 'ASP Clips Austin',
        state: 'TX'
      }
    ],
    headers: [
      {
        text: 'Name',
        value: 'name'
      },
      {
        text: 'Shop',
        value: 'shop'
      },
      {
        text: 'State',
        value: 'state'
      }
    ]
  })
}
</script>
